"use client"
import { useEffect, useRef, useState } from "react";
import { usePathname } from "next/navigation";
import $ from "jquery";

const CommonAppScript = () => {
  const pathname = usePathname();
  const effectRan = useRef(false);
  const [contact_Popup, setcontact_Popup] = useState(false);
  // const [isMainPopup, setIsMainPopup] = useState(false);
  const [isOfferPopup, setSetIsOfferPopup] = useState(false);
  const onOfferPopupClose = () => {
    setSetIsOfferPopup(false);
  };
  const callpopup = () => {
    setcontact_Popup(!contact_Popup);
  };
  function getCookie(name) {
    var cookieName = name + "=";
    var cookieArray = document.cookie.split(";");
    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return "";
  }
  function setCookie(name, value, hours) {
    var expirationDate = new Date();
    expirationDate.setTime(
      expirationDate.getTime() + hours * 60 * 60 * 24 * 30
    );
    var expires = "expires=" + expirationDate.toUTCString();
    document.cookie = name + "=" + value + "; " + expires;
  }
  useEffect(() => {

    function keypress(event) {
      if (event.keyCode === 27) {
        $("body").removeClass("overflowhidden");
        $(".appendpopup, .testipopup, .storypopup, .popupmainclass").hide();
      }
    }
    document.addEventListener('keydown', keypress);

    if (effectRan.current === false) {
      $(document).ready(function () {
        var referralLink = "";
        referralLink = document.referrer;

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (
          getCookie("referralLinkDataVLMS") == null ||
          getCookie("referralLinkDataVLMS") == undefined ||
          getCookie("referralLinkDataVLMS") == "undefined" ||
          getCookie("referralLinkDataVLMS").trim() == ""
        ) {
          if (
            referralLink == "" ||
            referralLink == undefined ||
            referralLink == "undefined" ||
            referralLink == null
          ) {
            if (urlParams.get("utm_source") != null) {
              setCookie("referralLinkDataVLMS", urlParams.get("utm_source"), {
                maxAge: 1,
              });
            } else {
              setCookie("referralLinkDataVLMS", "", { maxAge: 1 });
            }
          } else {
            setCookie("referralLinkDataVLMS", referralLink, { maxAge: 1 });
          }
        }

        if (
          getCookie("landingPageDataVLMS") == null ||
          getCookie("landingPageDataVLMS") == undefined ||
          getCookie("landingPageDataVLMS") == "undefined" ||
          getCookie("landingPageDataVLMS").trim() == ""
        ) {
          setCookie("landingPageDataVLMS", window.location.href, { maxAge: 2 * 2 });
        }
        // gclid
        const extractGclid = (url) => {
          const parsedUrl = new URL(url);
          const gclid = parsedUrl.searchParams.get('gclid');
          return gclid;
        };
        function GetURLParameter(sParam) {
          var sPageURL = window.location.search.substring(1);
          var sURLVariables = sPageURL.split("&");
          for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("=");
            if (sParameterName[0] == sParam) {
              return decodeURIComponent(sParameterName[1]);
            }
          }
        }
        var _gclid = "";
        _gclid = GetURLParameter("gclid") || extractGclid(getCookie("landingPageDataVLMS"));
        if (
          _gclid == null &&
          _gclid == undefined &&
          _gclid == "undefined" &&
          _gclid.trim() == ""
        ) {
          if (
            localStorage.VLMSgclidData == null ||
            localStorage.VLMSgclidData == undefined ||
            localStorage.VLMSgclidData == "undefined" ||
            localStorage.VLMSgclidData.trim() == ""
          ) {
            localStorage.VLMSgclidData = _gclid;
          }
        } else {
          localStorage.VLMSgclidData = _gclid;
        }
        localStorage.VLMSindexurl = window.location.href;
      });
      return () => {
        effectRan.current = true;
      };
    }
    return () => {
      document.removeEventListener("keydown", keypress);
    };
  }, []);

  useEffect(() => {
    // Disable right-click
    document.addEventListener("contextmenu", (e) => e.preventDefault());

    function ctrlShiftKey(e, keyCode) {
      return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
    }
    document.onkeydown = (e) => {
      // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
      if (
        event.keyCode === 123 ||
        ctrlShiftKey(e, "I") ||
        ctrlShiftKey(e, "J") ||
        ctrlShiftKey(e, "C") ||
        (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
      )
        return false;
    };

    // (function agent() {
    //   const keyevents = ["keydown", "keypress", "keyup"];
    //   const rejectOtherHandlers = (e) => {
    //     e.stopPropagation();
    //     if (e.stopImmediatePropagation) e.stopImmediatePropagation();
    //     if (e.keyCode == 27) {
    //       var appendPopup = document.querySelector('.appendpopup');
    //       var testiPopup = document.querySelector('.testipopup');
    //       var storyPopup = document.querySelector('.storypopup');
    //       var popupMainClass = document.querySelector('.popupmainclass');
    //       var podcastsplays = document.querySelectorAll('.podcastspause');

    //       if (appendPopup || testiPopup || storyPopup || popupMainClass) {
    //         $("body").removeClass("overflowhidden");
    //         $(".appendpopup, .testipopup, .storypopup").find("iframe").attr("src", "");
    //         $(".appendpopup, .testipopup, .storypopup, .popupmainclass").hide();
    //       }
    //       if (podcastsplays.length > 0) {
    //         podcastsplays.forEach(function (podcast) {
    //           podcast.click();
    //         });
    //       }
    //     }
    //   };

    //   keyevents.forEach((evt) => {
    //     document.addEventListener(evt, rejectOtherHandlers, true);
    //   });

    //   return () => {
    //     keyevents.forEach((evt) => {
    //       document.removeEventListener(evt, rejectOtherHandlers, true);
    //     });
    //   };
    // })();

    document.addEventListener("copy", function (e) {
      e.preventDefault();
      var customMessage = "OOPS! You can't copy the text";
      e.clipboardData.setData("text/plain", customMessage);
    });

   
  }, []);

  // useEffect(() => {
  //   gtag.pageview(pathname);
  // }, [pathname]);


  // useEffect(() => {
  //   hotjar.initialize(2035961, 6);
  // }, []);
  // // ga4
  // useEffect(() => {
  //   initGA();
  // }, []);
  return <></>;
}

export default CommonAppScript