import Link from "next/link";
import React from "react";
import style from "@/Ui_Elements/css/Button_Common.module.scss";
import Image from "next/image";

function Button_Common({ href = "#", classname = "", text, type = "link" }) {
  if (type === "btn") {
    return <button className={`${style["commonbtnstyle"]} ${classname}`}>{text}</button>;
  } else if (type === 2) {
    return (
      <>
        <Link href={href} className={`${style["read-more"]} ${classname}`}>
          {text}
          <span>
            <Image src="/images/circle-arrow.svg" alt="Vidyalaya LMS" width={0} height={0} />
          </span>{" "}
        </Link>
      </>
    );
  } else if (type === 3) {
    return (
      <>
        <Link href={href} className={`${style["read-more"]} ${style["bluebgorangebtn"]} ${classname}`}>
          {text}
          <span>
            <Image src="/images/circle-arrow.svg" alt="Vidyalaya LMS" width={0} height={0} />
          </span>{" "}
        </Link>
      </>
    );
  } else {
    return (
      <>
        <Link href={href} className={`${style["commonbtnstyle"]} ${classname}`}>
          {text}
        </Link>
      </>
    );
  }
}

export default Button_Common;

{
  /* return (
    <>
    {type === `btn` ? (
        <button className={`${style["commonbtnstyle"]} ${classname}`}>
        {text}
      </button>
      ) : (
        <Link href={href} className={`${style["commonbtnstyle"]} ${classname}`}>
          {text}
        </Link>
      )}
       </>
  ) */
}
